@use '~@angular/material' as mat;

@mixin report-card-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $text-color: mat.get-color-from-palette($foreground, text);
  $secondary-text-color: mat.get-color-from-palette(
    $foreground,
    secondary-text
  );
  $background-color: mat.get-color-from-palette($background, background);

  app-report-card .mat-card .mat-card-content .mat-icon {
    color: mat.get-color-from-palette($accent);
  }

  app-report-card .mat-card .mat-card-content .mat-icon-button .mat-icon {
    color: $secondary-text-color;
  }

  app-report-card .card-title {
    color: $text-color;
  }

  app-report-card .card-desc {
    color: $secondary-text-color;
  }
}
