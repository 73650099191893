@use '~@angular/material' as mat;

@mixin signin-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .login-header {
    background-image: linear-gradient(
      60deg,
      mat.get-color-from-palette($accent, 300),
      mat.get-color-from-palette($accent, 600)
    );
  }

  .login-header > div:first-child {
    color: mat.get-color-from-palette($accent, default-contrast);
  }

  .login-header > .login-icon {
    color: mat.get-color-from-palette($accent, default-contrast);
  }

  app-signin .mat-input-element {
    color: mat.get-color-from-palette($foreground, text) !important;
  }
}
