@use '~@angular/material' as mat;

@include mat.core();

// Define custom color palette: http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5
// $covprimary: (
//   50: #e0f3fc,
//   100: #b3e2f7,
//   200: #80cff2,
//   300: #4dbcec,
//   400: #26ade8,
//   500: #009fe4,
//   600: #0097e1,
//   700: #008ddd,
//   800: #0083d9,
//   900: #0072d1,
//   A100: #f9fcff,
//   A200: #c6e2ff,
//   A400: #93c8ff,
//   A700: #7abbff,
//   contrast: (
//     50: #000000,
//     100: #000000,
//     200: #000000,
//     300: #000000,
//     400: #000000,
//     500: #ffffff,
//     600: #ffffff,
//     700: #ffffff,
//     800: #ffffff,
//     900: #ffffff,
//     A100: #000000,
//     A200: #000000,
//     A400: #000000,
//     A700: #000000,
//   ),
// );
// $covaccent: (
//   50: #fff0e8,
//   100: #ffd9c6,
//   200: #ffbfa0,
//   300: #ffa57a,
//   400: #ff925e,
//   500: #ff7f41,
//   600: #ff773b,
//   700: #ff6c32,
//   800: #ff622a,
//   900: #ff4f1c,
//   A100: #ffffff,
//   A200: #ffffff,
//   A400: #ffd7cd,
//   A700: #ffc2b3,
//   contrast: (
//     50: #000000,
//     100: #000000,
//     200: #000000,
//     300: #000000,
//     400: #000000,
//     500: #000000,
//     600: #000000,
//     700: #000000,
//     800: #000000,
//     900: #ffffff,
//     A100: #000000,
//     A200: #000000,
//     A400: #000000,
//     A700: #000000,
//   ),
// );
// $custom-theme-primary: mat-palette($covprimary);
// $custom-theme-accent: mat-palette($covaccent);

$ocgblue: (
  50 : #e0e9ee,
  100 : #b3c9d4,
  200 : #80a5b8,
  300 : #4d819b,
  400 : #266685,
  500 : #004b70,
  600 : #004468,
  700 : #003b5d,
  800 : #003353,
  900 : #002341,
  A100 : #76b4ff,
  A200 : #4397ff,
  A400 : #107bff,
  A700 : #006ef6,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);
$ocgaccentblue: (
  50 : #e0f3f9,
  100 : #b3e2f0,
  200 : #80cee6,
  300 : #4dbadb,
  400 : #26acd4,
  500 : #009dcc,
  600 : #0095c7,
  700 : #008bc0,
  800 : #0081b9,
  900 : #006fad,
  A100 : #d7efff,
  A200 : #a4daff,
  A400 : #71c5ff,
  A700 : #58baff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
$custom-theme-primary: mat-palette($ocgblue);
$custom-theme-accent: mat-palette($ocgaccentblue);

// $custom-theme-primary: mat.define-palette(mat.$indigo-palette);
// $custom-theme-accent: mat.define-palette(mat.$teal-palette);
$custom-theme-warn: mat.define-palette(mat.$red-palette);

$custom-theme-warn-bg: mat.define-palette(mat.$red-palette, 200, 200, 200);

$custom-theme: mat.define-light-theme(
  $custom-theme-primary,
  $custom-theme-accent,
  $custom-theme-warn
);

$background: map-get($custom-theme, background);
$foreground: map-get($custom-theme, foreground);

// Insert custom foreground color
// Background and foreground structure: https://stackoverflow.com/questions/45382529/angular-2-material-foreground
// Merge settings returned by map_get: https://stackoverflow.com/questions/43919927/angular-material2-theming-how-to-set-app-background
// $custom-text-color: darkviolet;
// $custom-secondary-text-color: green;
// $custom-disabled-text-color: darkslategray;
// $foreground: map_merge(
//   $foreground,
//   (
//     text: $custom-text-color,
//   )
// );
// $foreground: map_merge(
//   $foreground,
//   (
//     secondary-text: $custom-secondary-text-color,
//   )
// );
// $foreground: map_merge(
//   $foreground,
//   (
//     disabled-text: $custom-disabled-text-color,
//   )
// );
// $custom-theme: map_merge(
//   $custom-theme,
//   (
//     foreground: $foreground,
//   )
// );

@include mat.all-component-themes($custom-theme);

@include custom-theme($custom-theme);

// general styles
.primary-color {
  color: mat.get-color-from-palette($custom-theme-primary) !important;
}
.accent-color {
  color: mat.get-color-from-palette($custom-theme-accent) !important;
}
.warn-color {
  color: mat.get-color-from-palette($custom-theme-warn) !important;
}

.primary-bg-color {
  background-color: mat.get-color-from-palette($custom-theme-primary) !important;
}
.accent-bg-color {
  background-color: mat.get-color-from-palette($custom-theme-accent) !important;
}
.warn-bg-color {
  background-color: mat.get-color-from-palette($custom-theme-warn-bg) !important;
}

.primary-contrast-color {
  color: mat.get-color-from-palette($custom-theme-primary, default-contrast);
}
.accent-contrast-color {
  color: mat.get-color-from-palette($custom-theme-accent, default-contrast);
}

.primary-text-color {
  color: mat.get-color-from-palette($foreground, text);
}
.accent-text-color {
  color: mat.get-color-from-palette($foreground, secondary-text);
}
.disabled-text-color {
  color: mat.get-color-from-palette($foreground, disabled-text);
}

.background-bg-color {
  background-color: mat.get-color-from-palette($background, background);
}
.disabled-bg-color {
  background-color: mat.get-color-from-palette($background, disabled-button);
}

// global settings
.k-window-titlebar {
  background-color: mat.get-color-from-palette($custom-theme-accent);
  color: mat.get-color-from-palette($custom-theme-accent, default-contrast);
  padding: 12px 24px;
  margin-bottom: 20px;
}
