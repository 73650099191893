@use '~@angular/material' as mat;

@mixin editor-identities-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $text-color: mat.get-color-from-palette($foreground, text);
  $secondary-text-color: mat.get-color-from-palette($foreground, secondary-text);
  $background-color: mat.get-color-from-palette($background, background);

  .k-pager-numbers .k-state-selected.k-link {
    color: mat.get-color-from-palette($primary, default-contrast) !important;
    background-color: mat.get-color-from-palette($primary);
  }

  .k-pager-numbers .k-state-selected:hover {
    color: mat.get-color-from-palette($primary, default-contrast);
    background-color: mat.get-color-from-palette($primary);
  }

  .k-grid th {
    color: $text-color;
  }
  .k-grid th .k-checkbox {
    border-color: $secondary-text-color;
  }
  .k-grid th .k-checkbox:indeterminate {
    color: mat.get-color-from-palette($primary, default-contrast);
    background-color: mat.get-color-from-palette($accent);
    border-color: mat.get-color-from-palette($accent);
  }
  .k-grid th .k-checkbox:checked {
    color: mat.get-color-from-palette($primary, default-contrast);
    background-color: mat.get-color-from-palette($accent);
    border-color: mat.get-color-from-palette($accent);
  }

  .k-grid tr {
    color: $text-color;
  }
  .k-grid tr.k-state-selected > td {
    color: $text-color !important;
  }
  .k-grid tr:hover {
    color: $text-color !important;
  }
  .k-grid tr.invalid {
    background-color: $background-color;
    color: $text-color;
  }
  .k-grid tr .k-checkbox {
    border-color: $secondary-text-color;
  }
  .k-grid tr .k-checkbox:checked {
    color: mat.get-color-from-palette($primary, default-contrast);
    background-color: mat.get-color-from-palette($accent);
    border-color: mat.get-color-from-palette($accent);
  }

  .k-grid .k-grid-pager {
    color: $secondary-text-color;
  }
  .k-grid .k-grid-pager .k-pager-numbers .k-link {
    color: $secondary-text-color;
  }
  .k-grid .k-grid-pager .k-pager-nav:hover {
    color: $secondary-text-color;
  }

  .k-grid .k-grid-filter {
    color: $secondary-text-color;
    opacity: 0.7;
  }
  .k-grid .k-grid-filter.k-state-active {
    color: mat.get-color-from-palette($primary, default-contrast);
    background-color: mat.get-color-from-palette($primary);
    opacity: 1;
  }
  .k-grid .k-grid-filter.k-state-active:hover {
    color: mat.get-color-from-palette($primary, default-contrast);
    background-color: mat.get-color-from-palette($primary);
    opacity: 1;
  }
  .k-grid .k-grid-filter:hover {
    color: $secondary-text-color;
    opacity: 1;
  }
  .k-grid .k-grid-filter:focus {
    color: $secondary-text-color;
    opacity: 1;
  }

  .k-grid .k-grid-header .k-header .k-column-resizer {
    color: mat.get-color-from-palette($primary);
  }

  .k-grid-filter-popup .k-action-buttons .k-button {
    color: $text-color;
  }
  .k-grid-filter-popup .k-action-buttons .k-button.k-primary {
    color: mat.get-color-from-palette($primary);
  }

  app-editor-identities .label-attribute-name {
    color: $secondary-text-color;
  }

  app-editor-identities .label-attribute-desc {
    color: $secondary-text-color;
  }

  app-editor-identities .export-button {
    color: $secondary-text-color;
  }

  app-editor-identities .clipboard-icon {
    color: $secondary-text-color;
  }
}
