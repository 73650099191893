@use '~@angular/material' as mat;

@mixin editor-boolean-theme($theme) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $secondary-text-color: mat.get-color-from-palette($foreground, secondary-text);

  app-editor-boolean .label-attribute-name {
    color: $secondary-text-color;
  }

  app-editor-boolean .label-attribute-desc {
    color: $secondary-text-color;
  }
}
