@use '~@angular/material' as mat;

@mixin editor-for-each-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  $borderColor: #0000 90deg, mat.get-color-from-palette($accent, 100) 0;

  .corner-box {
    /* thickness of the border */
    --b: 3px;
    /* width of border */
    --w: 20px;


    border: var(--b) solid #0000;
    /* space for the border */
    --_p: var(--w) var(--w) border-box no-repeat;
    background:
      conic-gradient(from 90deg at top var(--b) left var(--b), $borderColor) 0 0 / var(--_p),
      conic-gradient(from 180deg at top var(--b) right var(--b), $borderColor) 100% 0 / var(--_p),
      conic-gradient(from 0deg at bottom var(--b) left var(--b), $borderColor) 0 100% / var(--_p),
      conic-gradient(from -90deg at bottom var(--b) right var(--b), $borderColor) 100% 100% / var(--_p);

    padding: 10px;

  }
}
